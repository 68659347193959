<template>
  <div class="page-auth">
    <v-container fill-height fluid :class="[!$store.state.user ? 'pa-0' : '']">
      <div class="d-flex fill-height" style="width: 100%;">
        <div v-if="!forgotPassword.show && $store.state.screenWidth > 414" class="cover-img hidden-md-downfill-height banner-area" style="background-image: url(/images/login.jpg);"/>
        <div class="d-flex align-center justify-center flex-column fill-height login-area" :style="{ width: !forgotPassword.show && $store.state.screenWidth > 414 ? '50%' : '100%' }">
          <v-card
            light
            class="px-2 py-10 rounded-xl"
            style="width: 80%;max-width: 500px;"
          >
            <v-card-text v-if="!forgotPassword.show" class="px-8 pt-4">
              <div v-if="$store.state.screenWidth > 414" class="headline brown--text text-center font-weight-bold pb-8" style="color:#BB6906!important;">
                Welcome
              </div>
              <div v-else class="headline brown--text text-center font-weight-bold pb-8" style="color:#BB6906!important;">
                <img src="/images/new/logo-h-color.png" style="height: 40px;">
              </div>
              <v-row no-gutters class="pb-6">
                <v-col cols="5" class="d-flex align-center justify-center">
                  <v-divider />
                </v-col>
                <v-col cols="2" class="grey--text d-flex align-center justify-center">
                  Login
                </v-col>
                <v-col cols="5" class="d-flex align-center justify-center">
                  <v-divider />
                </v-col>
              </v-row>
              <v-text-field
                v-if="loginAdmin"
                :name="loginAdmin ? 'username' : 'admin'"
                ref="loginAdmin"
                v-model="login.admin"
                label="Email Admin"
                color="primary"
                outlined
                autocomplete="disabled"
                class="rounded-xl"
                dense
                @keypress.enter="DoLogin"
              ></v-text-field>
              <v-text-field
                ref="loginUsername"
                v-model="login.username"
                :name="loginAdmin ? 'master' : 'username'"
                :label="loginAdmin ? 'Email Master Admin' : 'Email Account'"
                color="primary"
                outlined
                hide-details
                autocomplete="disabled"
                class="rounded-xl mb-6"
                dense
                @keypress.enter="DoLogin"
              ></v-text-field>
              <v-text-field
                ref="loginPassword"
                v-model="login.password"
                label="Password"
                color="primary"
                outlined
                dense
                hide-details
                autocomplete="disabled"
                class="rounded-xl mb-6"
                :type="togglePassword ? 'text' : 'password'"
                :append-icon="togglePassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePassword = !togglePassword"
                @keypress.enter="DoLogin"
              ></v-text-field>
              <div class="text-right pb-3 pt-2">
                <span class="d-inline-block py-2 caption text-capitalize text-right" style="cursor: pointer;" @click.prevent="forgotPassword.email = '', forgotPassword.show = true">
                  Forgot Password?
                </span>
              </div>
              <v-btn
                depressed
                block
                class="rounded-xl caption font-weight-bold text-capitalize white--text"
                style="background-color: #BB6906;letter-spacing: .5px;"
                @click.prevent="DoLogin"
              >
                LOGIN
              </v-btn>
            </v-card-text>
            <v-card-text v-else class="px-8 pt-4">
              <div class="headline brown--text text-center font-weight-bold pb-8" style="color:#BB6906!important;">
                <img src="/images/new/logo-h-color.png" style="height: 40px;">
              </div>
              <v-row no-gutters class="pb-6">
                <v-col cols="4" class="d-flex align-center justify-center">
                  <v-divider />
                </v-col>
                <v-col cols="4" class="grey--text caption text-center d-flex align-center justify-center">
                  Forgot Password
                </v-col>
                <v-col cols="4" class="d-flex align-center justify-center">
                  <v-divider />
                </v-col>
              </v-row>
              <v-text-field
                v-model="forgotPassword.email"
                label="Input Email"
                color="primary"
                type="email"
                required
                outlined
                dense
                hide-details
                autocomplete="disabled"
                class="rounded-xl mb-6"
                @keypress.enter="SendPassword()"
              ></v-text-field>
              <v-btn
                depressed
                block
                class="rounded-xl caption font-weight-bold text-capitalize white--text"
                style="background-color: #BB6906;letter-spacing: .5px;"
                :disabled="!$validateEmail(forgotPassword.email)"
                @click.prevent="SendPassword()"
              >
                Send Password
              </v-btn>
              <div class="text-center pt-4" style="width: 100%!important;">
                <span class="green--text font-weight-bold text--darken-1 caption" style="cursor: pointer;width: 100%;" @click.prevent="forgotPassword.email = '', forgotPassword.show = false">
                  Back to Login
                  <v-icon small class="ml-1 green--text text--darken-1">
                    mdi-arrow-right
                  </v-icon>
                </span>
              </div>
            </v-card-text>
          </v-card>
          <div class="caption-small pt-10">
            Copyright © 2021 eBright Skin CRM
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    togglePassword: false,
    loginAdmin: false,
    forgotPassword: {
      show: false,
      email: ''
    },
    login: {
      username: '',
      password: '',
      admin: null
    }
  }),
  mounted () {
    if (location.href.match(/\?admin/)) {
      this.loginAdmin = true
      if (this.$route.query.admin) {
        this.login.admin = this.$route.query.admin
      }
    }
  },
  methods: {
    DoLogin () {
      if (!this.login.username) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Username tidak valid' })
        this.$refs.loginUsername.focus()
      } else if (!this.login.password) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Password tidak valid' })
        this.$refs.loginPassword.focus()
      } else {
        this.$store.dispatch('LOGIN', this.login).then(res => {
          if (res.status) {
            if (res.data.data.response) {
              this.$store.dispatch('POPUP', {
                t: this,
                show: true,
                type: '',
                title: 'Information',
                text: 'Login Success!'
              }).then((res) => {
              })
            } else {
              this.$store.commit('SNACKBAR', { show: true, text: res.data.data.message })
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: res.data.data.message })
          }
        })
      }
    },
    SendPassword () {
      if (!this.$validateEmail(this.forgotPassword.email)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please input valid email' })
        return false
      }
      this.$store.dispatch('SEND_PASSWORD', this.forgotPassword.email).then(res => {
        if (res.status) {
          if (res.data.data.response) {
            this.$store.commit('SNACKBAR', { show: true, text: 'Success, please check your email' })
            this.forgotPassword.show = false
            this.forgotPassword.email = ''
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
          }
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Ops, something went wrong' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-auth {
  position: fixed;
  z-index: 20;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #fafafa;
  .logo {
    width: 75px;
  }
  .banner-area {
    width: 50%;
  }
  .login-area {
    width: 50%;
  }
}
.theme--dark {
  .page-auth {
    background: #3A3F52;
  }
}
</style>
